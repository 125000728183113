import { Button, Grid, TextField, Typography,InputAdornment } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import '../Styles/LoginStyle.css';
import krishnalogo3 from '../images/krishnalogo.png';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import { Apiaddress } from '../Utility';
import { toast,ToastContainer } from 'react-toastify';
import axios from 'axios';
import SendMail from './SendMail';

function LoginPage() {

  const navigate=useNavigate();
  const [email,setemail]=useState("");
  const [password,setpassword]=useState("");
  const [error,seterror]=useState(false);
  const [show, setShow] = useState(false);

  const [openEmail, setOpenEmail] = React.useState(false);
  const handleOpenSendEmail = () => setOpenEmail(true);
  const handleCloseSendEmail = () => setOpenEmail(false);

  const handleLogin= async ()=>{
    if(email=="" || password==""){
      toast.warning("Please fill the required fields !!",{
        position:"top-right"
      })
      return;
    }
    try {
      let data={
        email:email,
        password:password,
      }
      const url=Apiaddress + "/admin/login";
      const res = await axios.post(url, data);
      console.log(res?.data?.data);
      sessionStorage.setItem("user_token", res?.data?.data?.userAuthToken);
      navigate('/');
    } catch (error) {
      toast.error("Credential is wrong !!",{
        position:"top-right"
      })
    }
    setemail("");
    setpassword("");
  }

  return (
    <div className='background'>   
        <Stack sx={{width:"100%",padding:"5%",alignItems:"center",minHeight:"100vh",justifyContent:"center"}}>
            <Stack direction="row" sx={{width:"100%",justifyContent:"center",alignItems:'center',marginBlock:"1%",marginRight:"5%"}}>
                <img src={krishnalogo3} className='krishnaLogo'></img>
                <Stack sx={{alignItems:"start"}}>
                    <p className='title-text'>ADMIN LOGIN</p>
                    <p className='text-subtitle' style={{textAlign:"center",width:"100%"}}>IIYC Lecture Repo</p>
                    <Box sx={{width:'100%',margin:"5px auto",border:"2px solid #a04e4e"}}></Box>
                </Stack>
            </Stack>
            <Stack sx={{
              width:"50%",marginBlock:"2%",
              '@media (max-width: 768px)': {
                width: '90%',
              },
              '@media (max-width: 600px)': {  
                width: '100%',
              },
              '@media (max-width: 480px)': {
                width: '100%',
              },
              }}>
              <TextField variant="standard" color="error" focused placeholder="Email *" required type="email" sx={{Color:'#ff8c00',marginBottom:"25px",width:"100%"}} value={email} onChange={(e)=>{setemail(e.target.value)}}/>
              <TextField variant="standard" color="error" focused placeholder="Password *" required  sx={{color:'#ff8c00',marginBottom:"0px",width:"100%"}} value={password} onChange={(e)=>{setpassword(e.target.value)}}
              type={show ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                  <IconButton onClick={() => setShow(!show)}>
                  {show ? (
                    <VisibilityOff sx={{ color: "#99B2C6" }} />
                    ) : (
                    <Visibility sx={{ color: "#99B2C6" }} />
                    )}
                    </IconButton>
                  </InputAdornment>
                  ),
                  }}
                />
                <Stack direction="row" justifyContent="right" sx={{margin:'15px 0px'}}>
                  <Typography sx={{color:"#cb4154",fontSize:"14px",cursor:"pointer"}}
                    onClick={handleOpenSendEmail}
                  >
                    Forgot Password?
                  </Typography>
                </Stack>

                {error && <Typography sx={{fontFamily:"Josefin Slab",color:"red"}}>Credentials is wrong !!</Typography>}
                <Box sx={{textAlign:"center"}}>
                <Button variant="contained" size="medium"  sx={{letterSpacing:"0.2rem",boxShadow:"none",borderRadius:"0px",padding:"7px 20px 7px 25px",color:"#a04e4e",margin:"20px 0px 20px 0px",background: 'none',border:"1.5px solid red","&:hover": {backgroundColor: '#a04e4e',color:"white",border:"1.5px solid #a04e4e"}}} onClick={handleLogin}>Login</Button>
                </Box>
            </Stack>   
        </Stack>
      <SendMail
        handleClose={handleCloseSendEmail}
        open={openEmail}
      />
    </div>
  )
}

export default LoginPage