import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import krishnalogo3 from '../images/krishnalogo.png';

function Footer() {
  return (
    <div>
        <Box sx={{backgroundColor:"#d4ad76",padding:"2% 10%"}}>
         <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",background:"#d4ad76"}}>
                   <img src={krishnalogo3} className='Image' style={{}}></img>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={8}>
                <Box sx={{height:"100%",justifyContent:'center',display:"flex",flexDirection:"column",background:"transparent"}}>
                  <Box sx={{
                    textAlign:"left",
                    '@media (max-width: 900px)': {
                    textAlign:"center",
                    marginTop:"-20px"
                    }
                    }}>
                   <Typography sx={{color:"#55503d",fontFamily: 'PT Serif',fontSize:"1.2rem",fontWeight:"540",letterSpacing:"0.2rem",marginBottom:"2%"}}>I I Y C</Typography>
                   <Typography sx={{color:"#5c5a54",fontFamily: 'Serif',fontSize:"1rem"}}>Dedicated to the pursuit of devotion, this platform offers a treasure trove of lectures by active ISKCON leaders engaged in youth outreach. Discover a meticulously categorized collection, compiled for your convenience, to deepen your spiritual journey.</Typography>
                  </Box>
                </Box>
            </Grid>
        </Grid>
        </Box>

        <Stack justifyContent="center" alignItems="center" sx={{
          height:"40px",backgroundColor:"rgba(85,69,47)"
          }}>
        </Stack>
    </div>
  )
}

export default Footer