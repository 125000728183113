import { Avatar, Box, Button, Modal, Stack, TextField, Typography,CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import '../Styles/homeStyle.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from '@mui/icons-material/Cancel';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';
import { toast,ToastContainer } from 'react-toastify';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Apiaddress } from '../Utility';
import axios from 'axios';
import TitleBar from './Title';
import _ from 'lodash';

function RenderData({renderData,onPushData}) {

  let lastElement=_.last(renderData);

  const [loading,setloading]=useState(false);
  const [trigger,setTrigger]=useState(false);
  const [curPage,setCurPage]=useState(1);
  const [totalPage,setTotalPage]=useState();
  const [pageRange, setPageRange] = useState([0, 6]);
  const [NoPages, setNopages] = useState(null);

  useEffect(()=>{
    let len=lastElement?.length;
      let total;
      if(len%10==0){
        total=parseInt(len/10); 
      }else{
        total=parseInt(len/10)+1;
      }
      setTotalPage(total);
  },[lastElement]);

  const preClick=()=>{
    if(curPage!==1){
      setCurPage(curPage-1);
    }
  }

  const nextClick=()=>{
    if(curPage>=totalPage){
      return;
    }else{
      setCurPage(curPage+1);
    }
  }

  return (
    <div>
        <Stack direction="column" sx={{marginBlock:"3%",minHeight:"60vh"}}>
              {loading?
              <Stack sx={{ color: 'grey.500',dispaly:"flex",justifyContent:"center",alignItems:"center",marginTop:"20%"}}>
                <CircularProgress color="inherit" />
              </Stack>:
              <Stack>
                {lastElement?.length==0?
                    <Box sx={{textAlign:"center",marginTop:"20%"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"gray",}}>No Matches Found !!</Typography>
                    </Box>:
                    <Box>
                    {lastElement && lastElement?.slice(curPage*10-10,curPage*10)?.map((ele, index) =>{
                        return(
                            <Stack className='keyword-box' sx={{
                              justifyContent:"space-between",
                              display:"flex",
                              alignItems:"center",
                              flexDirection:"row",
                              '@media (max-width: 768px)': {
                                flexDirection:"column",
                                alignItems:"start",
                                rowGap:"0px"
                              },
                            }}>
                                <Stack justifyContent="center" alignItems="" sx={{
                                  margin:"1rem 2rem",
                                  '@media (max-width: 768px)': {
                                     width:"80%"
                                    },
                                  }}>
                                  {ele.name?
                                    <p className="keyword-title-text">{ele?.name?.charAt(0).toUpperCase() + ele?.name?.slice(1)}</p>
                                    :
                                    <a href={ele?.link} target="_blank" style={{textDecoration:"none"}}>{ele?.description}</a>
                                  }
                                    
                                </Stack>
                              {ele.name &&
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1.5} sx={{width:"10%",margin:"0rem 2rem",
                                '@media (max-width: 768px)': {
                                     justifyContent:"start",
                                     width:"80%",
                                     marginBottom:"15px"
                                    },
                                }}>
                                    <Button variant="contained" sx={{background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)",
                                    '@media (max-width: 420px)': {
                                     paddingBlock:"10px"
                                    },  
                                  }}
                                        onClick={()=>{onPushData(ele?.items)}}>
                                        <Box sx={{ textTransform: "capitalize" }}>View</Box>
                                        <RemoveRedEyeIcon fontSize="small" sx={{marginLeft:"5px"}}/>  
                                    </Button>
                                </Stack>
                              }
                            </Stack>
                        )
                    })}
                  </Box>
                  }
                </Stack>
                }
              </Stack>

              {lastElement?.length!=0 &&  
                    <Box sx={{ display: "flex", justifyContent: "center",marginBlock:"20px"}}>
                    {lastElement?.length!=0 && 
                        <Button variant="contained" sx={{background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)"}}
                        onClick={preClick}
                        >
                            <ArrowBackIosIcon fontSize="small" />
                            <Box sx={{ textTransform: "capitalize" }}>Prev</Box>
                        </Button>}

                        <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                          <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curPage}</Box>
                          <Box sx={{color: "gray",width: "30px",borderRadius: "5px",display: "grid",placeItems: "center"}}>of</Box>
                          <Box sx={{background: `#E3E4EB`,color: "gray",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{totalPage}</Box>
                        </Box>

                    {lastElement?.length!=0 && 
                        <Button variant="contained" sx={{background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)"}}
                        onClick={nextClick}
                        >
                            <Box sx={{ textTransform: "capitalize" }}>Next</Box>
                            <ArrowForwardIosIcon fontSize="small" />
                        </Button>
                    }
                    </Box>
                }  
    </div>
  )
}

export default RenderData