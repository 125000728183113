import { Avatar, Box, Button, Modal, Stack, TextField, Typography,CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import '../Styles/homeStyle.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from '@mui/icons-material/Cancel';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';
import { toast,ToastContainer } from 'react-toastify';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Apiaddress } from '../Utility';
import axios from 'axios';
import TitleBar from './Title';
import RenderData from './RenderData';
import _ from 'lodash';

function TemplatePage() {
  
  let state;
  const navigate=useNavigate();
  const [classData,setClassData] = useState();
  const [trigger,setTrigger]=useState(false);
  const [wholeData,setWholeData]=useState();
  const [renderData,setRenderData]= useState();

  //reading Data
  const readData= async ()=>{
    try {
      let url=Apiaddress + `/user/${state?._id}`;
      const res = await axios.get(url);
      setWholeData(res?.data?.data[0].lecture?.items);
    } catch (error) {
      console.log(error?.message);
    }
  }

  const formatRenderData=()=>{
    if(wholeData[0].type==="category"){
      setRenderData([wholeData]);
    }else{
      setRenderData([wholeData]);
    }
  }

  const onPushData=(data)=>{
    setRenderData([...renderData,data]);
  }

  const onPushBack=(ind)=>{
    let temp=renderData?.slice(0,ind+1);
    setRenderData(temp);
  }

  useEffect(()=>{
    if(wholeData){
      formatRenderData();
    }
  },[wholeData]);

  useEffect(()=>{
    state=JSON.parse(sessionStorage.getItem("speaker"));
    if(state){
      setClassData(state);
      readData();
    }
  },[trigger]);
  
  return (
    <div className='background'>
      <Navbar/>
        <Stack sx={{width:"100%",minHeight:"100vh",overflowY:"scroll",padding:"1% 4%"}}> 
          <TitleBar title="Class" speaker="Speaker" category={renderData?.length} onPushBack={onPushBack}/>
            <Stack sx={{
              justifyContent:"space-between",
              display:"flex",
              alignItems:"center",
              flexDirection:"row",
              '@media (max-width: 768px)': {
                flexDirection:"column",
                alignItems:"start",
                rowGap:"20px"
              },
              '@media (max-width: 450px)': {
                marginTop:"10px",
                marginBottom:"10px"
              }
            }}>
                <Stack>
                    <p className='text-head-title'>Lecture Directory</p>
                    <p className='text-sub-title'>{`List of lecture category assocoated with ${classData?.name?classData?.name:""}`}</p>
                </Stack>
            </Stack>

            <RenderData renderData={renderData} onPushData={onPushData}/>

            </Stack>
            <Footer/>
    </div>
  )
}

export default TemplatePage