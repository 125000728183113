import './App.css';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './component/Home';
import Speaker from './component/Speaker';
import TemplatePage from './component/TemplatePage';
import AddLecture from './component/AddLecture';
import { useState } from 'react';
import PasswordReset from './component/PasswordReset';
import Login  from './component/Login';

function App() {

  // Event listener for page load
  window.addEventListener('load', function() {
    sessionStorage.removeItem('user_token');
  });

  const [plank,setplank]=useState(true);
  return (
     <div>
      <BrowserRouter>
      <Routes>
        <Route exact path="/admin" element={<Login/>}/>
        <Route exact path="/" element={<Home plank={plank} setplank={setplank}/>}/>
        <Route path="/reset-password/:id" element={<PasswordReset/>} />
        <Route exact path="/lecture" element={<AddLecture plank={plank} setplank={setplank}/>}/>
        <Route exact path="/speaker" element={<Speaker plank={plank} setplank={setplank}/>}/>
        <Route exact path="/page" element={<TemplatePage/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
