import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import LanguageIcon from '@mui/icons-material/Language';
import krishnalogo from '../images/krishnalogo.png';
import pattern from '../images/pattern.png';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment, Modal, Stack, TextField } from '@mui/material';
import SendMail from './SendMail';
import { Apiaddress } from '../Utility';
import { toast,ToastContainer } from 'react-toastify';
import axios from 'axios';

const settings = ['Logout'];

function Navbar({plank,setplank}) {
const navigate=useNavigate();
const [pages,setpages]=useState([]);
const [token,setToken]= useState("");

  React.useEffect(()=>{
    let login=sessionStorage.getItem('user_token');
    (login!==""&& login!==null)?setpages(['Home', 'Add Lecture']):setpages(['Home']);
    setToken(login);
  },[plank]);

  const SelectOption=(page)=>{
    if(page=="Home"){
      navigate('/');
    } 
    else if(page=="Add Lecture"){
      navigate('/lecture');
    }
  }  

const [anchorElNav, setAnchorElNav] = React.useState(null);
const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userSubmit=(setting)=>{
    if(setting==="Logout"){
      handleLogout();
      setplank(!plank);
    }
  }

  const handleLogout= ()=>{
    sessionStorage.setItem("user_token","");
    navigate('/');
  }

  return (
    <div>
    <AppBar position="sticky" style={{}}>
      <Container maxWidth="xl" className="navbar" sx={{backgroundColor:"rgb(160,78,78)",padding:"7px 10px"}}>
        <Toolbar disableGutters>
          <Box sx={{padding:"0.5% 0%"}}>
             <img src={krishnalogo} style={{width:"80px"}}></img>
             <Typography
           
            sx={{
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: 'rgb(254,218,106)',
              textDecoration: 'none',
              textAlign:"center",
              fontSize:"8px",
              margin:"-5px 0px 0px 25px"
            }}
          >
            I  I  Y  C
          </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{color:"rgb(240,227,227)",margin:"0px 0px 0px 5px"}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" className="navoption" sx={{color:"#ff8c00"}} onClick={()=>SelectOption(page)}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },marginLeft:"30px" }}>

            {pages.map((page) => (
              <Button
                key={page}
                onClick={()=>{handleCloseNavMenu();SelectOption(page)}}
                sx={{mx:1,color: 'rgb(240,227,227)', display: 'block',fontWeight:"500",fontFamily: 'Helvetica',fontSize:"18px" }}
                className="navoption"
              >
                {page}
              </Button>
            ))}
          </Box>
          {token!=="" && token!==null &&
          <Box sx={{ flexGrow: 0,display:"flex",alignItems:"center",gap:"15px"}}>
            <Typography textAlign="center" className="navoption" sx={{fontWeight:"500",fontFamily: 'Helvetica',fontSize:"18px",cursor:"pointer"}}
            onClick={handleOpenUserMenu}
            >ADMIN MODE</Typography>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting,index) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu} sx={{}}>
                  <Typography textAlign="center" sx={{color:"#a04e4e",fontFamily: 'Helvetica'}}
                  onClick={()=>{userSubmit(setting)}}
                  >{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
    </div>
  )
}

export default Navbar


   

  