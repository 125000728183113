import { Backdrop, Box, Button, Fade, Modal, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { Apiaddress } from '../Utility';
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

function SendMail({
  handleClose,
  open
}){
    const [loading,setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [checkEmail,setCheckEmail] = useState(false);
    const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },  
    } = useForm();

    const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#f6f0e4',
    border: '2px solid #d4ad76',
    borderRadius:"10px",
    boxShadow: 24,
    p: 4,
    pt:2,
    '@media (max-width: 420px)': {
      width:350
    },
    '@media (max-width: 370px)': {
      width:300
    },
    '@media (max-width: 320px)': {
      width:275
    }
    }

    const sendmail=async (data)=>{
    setLoading(true);
    setCheckEmail(false);
    try {
      const url=Apiaddress + "/admin/send-mail";
      const res = await axios.post(url, data);
      console.log(res?.data?.data);
      setSuccess(true);
    } catch (error) {
      setCheckEmail(true);
    }
    setLoading(false);
  }

  const CloseTab=()=>{
    reset({send_email:""});
    setCheckEmail(false);
    setSuccess(false);
    handleClose(); 
  }

  return(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
      timeout: 500,
      }}
      >
      <Fade in={open}>
      <Box sx={style}>
      <Box sx={{ textAlign: "right" }}>
        <CloseIcon
          sx={{ color: "gray", cursor: "pointer" }}
            onClick={()=>{CloseTab()}}
        />
      </Box>
      <p style={{color:success?"green":"#cb4154",fontWeight:"450"}}>
        {success?"Check your Mail Inbox or Spam to set Password":" Enter your Registered Email ID"}
      </p>
        {!success && 
        <form onSubmit={handleSubmit(sendmail)}>
          <TextField variant="standard" color="error" focused
            sx={{
              width: "100%",
              fontFamily: "Product Sans",
              marginTop:"10px"
            }}
            id="outlined-error-helper-text"
            name="send_email"
            fullWidth
            {...register("send_email", { required: "Email is required.",
              pattern: {
              value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              message:
              "email is invalid",
              },
            })}
            error={Boolean(errors.send_email)}
            helperText={errors.send_email?.message}
          />
          {checkEmail && (
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1rem",
                color: "#eb6270",
                marginTop:"5px",
                fontFamily: "Product Sans",
              }}
            >
            Email is not Registered
            </Typography>
          )}
          <Stack direction="row" sx={{ marginTop: "20px"}}>
            {loading?
              <Button className='general-button'>
                <CircularProgress color="inherit" size="large" sx={{width:"25px",height:"25px",marginInline:"2rem"}}/>
              </Button>
              :
              <Button variant="contained" size="medium"  sx={{letterSpacing:"0.2rem",boxShadow:"none",borderRadius:"0px",marginTop:"10px",color:"#a04e4e",background: 'none',border:"1.5px solid red","&:hover": {backgroundColor: '#a04e4e',color:"white",border:"1.5px solid #a04e4e"}}}
                type="submit"
              >Send Mail
              </Button>
            }
          </Stack>
          </form>  
          }
          </Box>
        </Fade>
      </Modal>
  )

}

export default SendMail;