import { Avatar, Box, Button, Modal, Stack, TextField, Typography,CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import '../Styles/homeStyle.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from '@mui/icons-material/Cancel';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';
import { toast,ToastContainer } from 'react-toastify';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Apiaddress } from '../Utility';
import axios from 'axios';
import TitleBar from './Title';
import { Space, Table, Tag } from 'antd';
import Papa from 'papaparse';
import csvfile from "../CSV/sample.csv";
import Select from 'react-select'

function AddLecture({plank,setplank}) {

  const navigate=useNavigate(); 

  const [loading,setloading]=useState(false);
  const [trigger,setTrigger]=useState(false);
  const [name,setName] = useState("");
  const [csvData, setCSVData] = useState(null);
  const [headerData,setHeaderData] = useState(null);
  const [token,setToken] = useState();
  const [csvError,setCsvError] = useState(false);
  const [options, setOptions] = useState([]);
  
  // Custom styles for the React Select component
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width:"250px",
      border: "1px solid #a04e4e",
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 2px rgba(249, 191, 59, 0.2)' : null,
      '&:hover': {
        borderColor: state.isFocused ? null : '#a04e4e',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#f0f0f0' : null,
      color: state.isSelected ? '#a04e4e' : 'black',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }),
  };

  useEffect(()=>{
    let token=sessionStorage.getItem("user_token");
    setToken(token);
    setplank(!plank);
  },[plank]);
  
  //get All Speaker
  const getAllSpeaker = async ()=>{
    const headers = {
      Authorization: `JWT ${token}`,
    };
    try {
      let url=Apiaddress + `/admin/speaker`;
      const res = await axios.get(url,{headers});
      console.log(res?.data?.data);
      if(res?.data?.data?.length==0){
        setOptions([]);
      }
      else{
        let tempData=res?.data?.data;
        let formattedData=tempData?.map((ele)=>{
          return ({
            ...ele,
            value: ele?.name,
            label: ele?.name,
          });
        })
        setOptions(formattedData);
      }
    } catch (error) {
      console.log(error?.message);
    }
    setTrigger(!trigger); 
  }

  useEffect(()=>{
    getAllSpeaker();
  },[token])

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          let inputObject=result.data[0];
          const keys = Object.keys(inputObject);
          const resultArray = keys.map(key =>{
            let obj={
               title:key,
               dataIndex:key,
               key:key,
               render: (text) => (
                  <span style={{ color: text === null ? 'red' : null,backgroundColor: text === null ? 'red' : null}}>{text==null?"_________":text}</span>
                ),
              }
            return obj;
          }
          );
          const filteredData = result.data.filter(row => Object.values(row).some(value => value !== null && value !== ''));
          const checkEmptyCell= result.data.filter(row => Object.values(row).some(value => value == null || value == ''));
          setCsvError(checkEmptyCell.length>0);
          setHeaderData(resultArray);
          setCSVData(filteredData);
        },
        skipEmptyLines:true,
        header: true,
        dynamicTyping: true,
      });
    }
  };

  const removeField=(temp,str,data)=>{
    let tempSet= new Set();
    let len=data?.length;
    for(let i=0;i<len;i++){
      if(tempSet.has(data[i][str])){
        data.splice(i, 1);
        i--;
        len--;
      }else{
        let type=temp[data[i][str]][0].type;
        if(type==undefined){
          type=temp[data[i][str]][0].items.type;
        }
        tempSet.add(data[i][str]);
        data[i][str]={
        name:data[i][str],
        type:str,
        items:temp[data[i][str]]
        }
        delete data[i][type];
      }
    }
    return data; 
  }

  const helperFun=(str,data)=>{
    let temp={};
    let len=data?.length;
    for(let i=0;i<len;i++){
      let obj=data[i];
      let key=obj[str];
      let arr=[];
      if(temp[key]){
        arr=temp[key];
      }
      let tempObj={};
      if("link" in data[i]){
        tempObj.link=obj?.link;
        tempObj.description=obj?.description;
        tempObj.type="link";
      }
      else if("subcategory2" in data[i]){
        tempObj=obj?.subcategory2;
      }
      else if("subcategory1" in data[i]){
        tempObj=obj?.subcategory1;
      }
      arr.push(tempObj);
      temp[key]=arr;
    }
    let newarr=removeField(temp,str,data);
    temp={};
    return newarr;
  }

  const formatData=(data)=>{
    let first_obj=data[0];
    if("subcategory2" in first_obj){
      data=helperFun("subcategory2",data);
    }
    if("subcategory1" in first_obj){
      data=helperFun("subcategory1",data);
    }
    if("category" in first_obj){
      data=helperFun("category",data);
      data=data.map((ele)=>{
        return ele?.category;
      })
    }else{
      for(let i=0;i<data?.length;i++){
        data[i].type="link";
      }
    }
    return data;
  }

  const AddLecture= async ()=>{
    if(name===""){
      toast.warning("Please Select Speaker !!",{
        position:"top-right"
      })
      return;
    }
    if(!csvData){
      toast.warning("Please Upload CSV file !!",{
        position:"top-right"
      })
      return;
    }
    if(csvError){
      toast.error("Error in the CSV file !!",{
        position:"top-right"
      })
      return;
    }
    const keys = Object.keys(csvData[0]);
    const hasDescriptionAndLink = keys.includes('description') && keys.includes('link');
    if(!hasDescriptionAndLink){
      toast.error("Link and Description both should be present !!",{
        position:"top-right"
      })
      return;
    }
    try {
      let insertData = JSON.parse(JSON.stringify(csvData));
      let finalData=formatData(insertData);
      const headers = {
        Authorization: `JWT ${token}`,
      };
      const lecture={
        items:finalData
      }
      let url=Apiaddress + `/admin/add-lecture/${name?._id}`;
      const res = await axios.patch(url,{lecture:lecture},{headers});
      if(res?.status==200){
        toast.success("lecture is Added Successfully !!",{
        position: 'top-right'
        });
        clearData();
        navigate("/");
      }
    } catch (e) {
      if(e?.response?.status){
        toast.error("Unauthrised !!",{
        position:"top-right"
        })
      }else{
        toast.error("Error while performing operation !!",{
        position:"top-right"
      })
      }
    }
  }

  const clearData=()=>{
    setCSVData(null);
    setName("");
    setHeaderData(null);
    setTrigger(!trigger);
  }

  const downloadCsv=()=>{

    // Path to your existing CSV file
    const csvFilePath = csvfile;
    // Create a link element
    const a = document.createElement('a');
    a.href = csvFilePath;
    a.download = 'sample_file.csv';
    // Append the link to the body and click on it to trigger the download
    document.body.appendChild(a);
    a.click();
    // Clean up by removing the link
    document.body.removeChild(a);

  }

  return (
    <div className='background'>
      <Navbar plank={plank} setplank={setplank}/>
        <Stack sx={{width:"100%",minHeight:"100vh",overflowY:"scroll",padding:"4%"}}> 
            <Stack sx={{
              justifyContent:"space-between",
              display:"flex",
              minHeight:"100px",
              alignItems:"center",
              flexDirection:"row",
              '@media (max-width: 768px)': {
                flexDirection:"column",
                alignItems:"start",
                rowGap:"20px"
              },
              '@media (max-width: 450px)': {
                marginTop:"10px"
              }
            }}>
            <Stack>
                <p className='text-head-title'>Lecture Directory</p>
                <p className='text-sub-title'>Here you can add Lecture for speaker</p>
            </Stack>
                <Stack spacing={0} sx={{
                   justifyContent:"center",
                  '@media (max-width: 480px)': {
                    flexDirection:"column",
                    alignItems:"start",
                    rowGap:"0px",
                    columnGap:"0px",
                    marginBottom:"10px"
                  },
                }}>
                 
                <Box sx={{marginBottom:"0rem",
                '@media (max-width: 450px)': {
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"center",
                    alignItems:"start",
                    rowGap:"15px"
                  }
                }}>
              
              <Select 
                options={options}
                styles={customStyles}
                value={name}
                onChange={setName}
                />
            </Box>
            </Stack>
            </Stack>
            
            <Typography sx={{textAlign:"start",marginTop:"15px"}}>
              <span style={{fontWeight:"550",color:"#a04e4e"}}>Selected Speaker : </span>
                {name?name?.name?.charAt(0)?.toUpperCase() + name?.name?.slice(1):" __________"}
            </Typography>
            
            <Stack sx={{border:"1.5px solid orange",marginTop:"20px",padding:"10px 20px",borderRadius:"20px"}}>
              <Typography sx={{fontWeight:"550",color:"#a04e4e",fontSize:"18px"}}>
                Here You can download the Sample CSV file.
              </Typography>
              <Stack direction="row" sx={{marginTop:"8px",
                '@media (max-width: 768px)': {
                  flexDirection:"column",
                  rowGap:"10px"
                },
              }}>
              <Stack sx={{width:"50%",
                '@media (max-width: 768px)': {
                  alignItems:"start",
                  width:"100%"
                },
              }}>
              <ul style={{marginLeft:"20px",width:"100%",paddingRight:"20px"}}>
                <li>Column title should be same as in sample CSV file.</li>
                <li>category, subcategory1 and subcategory2 are optional fileds.</li>
              </ul>
              </Stack>
              <Stack alignItems="end" sx={{marginBlock:"10px",width:"50%",
                '@media (max-width: 768px)': {
                  alignItems:"start",
                  width:"100%"
                },
              }}>
              <Button variant="contained" sx={{
                    background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)",
                    width:"100px",
                    paddingBlock:'7px'
                    }}>
                    <Box sx={{ textTransform: "capitalize" }}
                      onClick={downloadCsv}
                    >Download</Box>
                  </Button>
              </Stack>
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center" 
              sx={{margin:"15px 0px",
                  '@media (max-width: 480px)': {
                    flexDirection:"column",
                    rowGap:"15px",
                    alignItems:"start"
                  },
                }}>
                <input 
                  type="file" 
                  onChange={handleFileChange}
                  accept=".csv,.xlsx"
                  style={{color:'brown',cursor:"pointer"}}
                />
                <Button variant="contained" sx={{
                    background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)",
                    width:"140px",
                    paddingBlock:'10px'
                    }}>
                    <Box sx={{ textTransform: "capitalize" }}
                      onClick={AddLecture}
                    >Add Lecture</Box>
                    <AddIcon fontSize="small" />  
                  </Button>
            </Stack>

            <Stack direction="column" sx={{marginBlock:"3%",minHeight:"60vh"}}>
              {loading?
              <Stack sx={{ color: 'grey.500',dispaly:"flex",justifyContent:"center",alignItems:"center",marginTop:"20%"}}>
                <CircularProgress color="inherit" />
              </Stack>:
              <Stack>
               <Table columns={headerData} dataSource={csvData} />
              </Stack>
              }
              </Stack>
            </Stack>
            <Footer/>
    </div>
  )
}

export default AddLecture