import { Avatar, Box, Button, Modal, Stack, TextField, Typography,CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import '../Styles/homeStyle.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from '@mui/icons-material/Cancel';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';
import { toast,ToastContainer } from 'react-toastify';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Apiaddress } from '../Utility';
import axios from 'axios';
import TitleBar from './Title';

function Speaker({plank,setplank}) {
  
  let state;
  const navigate=useNavigate();
  const [classData,setClassData] = useState();
  const [loading,setloading]=useState(false);
  const [name,setName]=useState();
  const [speakerList,setSpeakerList]=useState([]);
  const [selected,setSelected]=useState();
  const [token,setToken] = useState();

  const [trigger,setTrigger]=useState(false);
  const [curPage,setCurPage]=useState(1);
  const [totalSpeaker,setTotalSpeaker]=useState();
  const [pageRange, setPageRange] = useState([0, 6]);
  const [NoPages, setNopages] = useState(null);
  const [searchQuery,setSearchQuery] = useState(false);
  const [valueToSearch,setValueToSearch] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  useEffect(()=>{
    let token=sessionStorage.getItem("user_token");
    setToken(token);
    setplank(!plank);
  },[plank]);
  
  //reading Data
  const readData= async ()=>{
    setloading(true);
    try {
      let url=Apiaddress + `/user/all-speaker?id=${state?._id}&limit=${7}&page=${curPage}`;
      if(searchQuery){
        url= url + `&search=${valueToSearch}`
      }
      const res = await axios.get(url);
      console.log(res?.data?.data);
      if(res?.data?.data?.length==0){
        setSpeakerList([]);
      }
      else{
        setSpeakerList(res?.data?.data);
        setTotalSpeaker(res?.data?.count);
      }
    } catch (error) {
      console.log(error?.message);
    }
    setloading(false);
  }

  //searching of Keyword
  const searchSpeaker = async (val)=>{
    if(val===""){
      setSearchQuery(false);
    }
    else{
      let value = val.toLowerCase();
      setValueToSearch(value);
      setSearchQuery(true);
    }
    setTrigger(!trigger); 
    }
  
  //pagination
  const nextClick = () => {
    if (curPage !== NoPages) {
      setCurPage(curPage + 1);
      setTrigger(!trigger);
    }
  };

  const preClick = () => {
    if (curPage !== 1) {
      setCurPage(curPage - 1);
      setTrigger(!trigger);
    }
  };

  useEffect(() => {
    if (speakerList) {
      if (totalSpeaker % 7 == 0) {
        let temp = totalSpeaker / 7;
        setNopages(temp);
      } else {
        let temp = Math.floor(totalSpeaker / 7 + 1);  
        setNopages(temp);
      }
    }
  }, [speakerList]);

  useEffect(()=>{
    state=JSON.parse(sessionStorage.getItem("class"));
    if(state){
      setClassData(state);
      readData();
    }
  },[trigger]);
  
  const handleAddChange=async (e)=>{
    state=JSON.parse(sessionStorage.getItem("class"));
    let token=sessionStorage.getItem("user_token");
    e.preventDefault();
    try {
        if(selected){
            const headers = {
              Authorization: `JWT ${token}`,
            };
            let url=Apiaddress + `/admin/add-lecture/${selected}`;
            const res = await axios.patch(url,{name:name},{headers});
            if(res?.status==200){
                toast.success("Speaker is Updated Successfully !!",{
                position: 'top-right'
                })
            }
            }else{
              const headers = {
                Authorization: `JWT ${token}`,
              };
                let url=Apiaddress + `/admin/create-speaker`;
                const res = await axios.post(url,{name:name,class:state?._id},{headers});
                if(res?.status==200){
                    toast.success("Speaker is Added Successfully !!",{
                    position: 'top-right'
                    })
                }
            }
        }catch (e) {
            if(e?.response?.status){
              toast.error("Unauthrised !!",{
              position:"top-right"
            })
            }else{
              toast.error("This Speaker is already Exist !!",{
              position:"top-right"
            })
            }
        }
    clearField();
    handleCloseAdd();
    setTrigger(!trigger);  
}

  const clearField=()=>{
    setName("");
    setSelected(null);
  }

  const deleteSelectedData=async ()=>{
    try{
      const headers = {
        Authorization: `JWT ${token}`,
      };
      let url=Apiaddress + `/admin/speaker/${selected}`;
      const res = await axios.delete(url,{headers});
      if(res?.status==200){
        toast.success("Speaker is Deleted Successfully !!",{
        position: 'top-right'
        })
      }
      }catch(e){
        if(e?.response?.status){
          toast.error("Unauthrised !!",{
          position:"top-right"
        })
        }else{
          toast.error("Error while performing operation !!",{
          position:"top-right"
        })
        }
           
      }
      clearField();
      handleClose();
      setTrigger(!trigger);  
    }
  
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: '#f6f0e4',
        border: '2px solid #d4ad76',
        borderRadius:"20px",
        boxShadow: 24,
        textAlign:"center",
        p: 4,
        '@media (max-width: 400px)' : {
          width: '90%'
        },
        '@media (max-width: 350px)' : {
          width: '95%'
        },
    };

  return (
    <div className='background'>
      <Navbar plank={plank} setplank={setplank}/>
        <Stack sx={{width:"100%",minHeight:"100vh",overflowY:"scroll",padding:"1% 4%"}}> 
          <TitleBar title="Class" speaker="Speaker"/>
            <Stack sx={{
              justifyContent:"space-between",
              display:"flex",
              alignItems:"center",
              flexDirection:"row",
              '@media (max-width: 768px)': {
                flexDirection:"column",
                alignItems:"start",
                rowGap:"20px"
              },
              '@media (max-width: 450px)': {
                marginTop:"10px"
              }
            }}>
                <Stack>
                    <p className='text-head-title'>Speaker Directory</p>
                    <p className='text-sub-title'>{`List of Speakers assocoated with ${classData?.name?classData?.name:""}`}</p>
                </Stack>
                <Stack direction="row" spacing={0} sx={{
                  gap:3,
                  '@media (max-width: 480px)': {
                    flexDirection:"column",
                    alignItems:"start",
                    rowGap:"20px",
                    columnGap:"0px",
                    marginBottom:"10px"
                  },
                }}>
                  <TextField 
                    sx={{
                      "& .MuiInputBase-root": {
                      "& fieldset": {
                      borderColor: "#a04e4e !important",
                      borderRadius: "8px",
                      },
                      "&:hover fieldset active": {
                      borderColor: "#a04e4e",
                      },
                    },
                    '@media (max-width: 480px)': {
                      width:"130%"
                    },
                    '@media (max-width: 380px)': {
                      width:"115%"
                    },
                    }}
                    onChange={(e)=>{
                      searchSpeaker(e.target.value);
                      setCurPage(1);
                    }}
                    variant="outlined"
                    placeholder="Search"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: <SearchRoundedIcon sx={{ color: "#a04e4e", margin: 1}} />
                    }}/>
                  {token!=="" && token!==null  &&
                  <Button variant="contained" sx={{
                    background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)",
                    '@media (max-width: 480px)': {
                      // width:"130%",
                      paddingBlock:'10px',
                    },
                    }}>
                    <Box sx={{ textTransform: "capitalize" }}
                        onClick={handleOpenAdd}
                    >Add Speaker</Box>
                    <AddIcon fontSize="small" />  
                  </Button>
                  }
                </Stack>
            </Stack>

            <Stack direction="column" sx={{marginBlock:"3%",minHeight:"60vh"}}>
              {loading?
              <Stack sx={{ color: 'grey.500',dispaly:"flex",justifyContent:"center",alignItems:"center",marginTop:"20%"}}>
                <CircularProgress color="inherit" />
              </Stack>:
              <Stack>
                {speakerList?.length==0?
                    <Box sx={{textAlign:"center",marginTop:"20%"}}>
                        <Typography sx={{fontSize:"25px",fontWeight:"500",color:"gray",}}>No Matches Found !!</Typography>
                    </Box>:
                    <Box>
                    {speakerList && speakerList?.map((ele, index) =>{
                    if (index >= pageRange[0] && index <= pageRange[1]){
                        return(
                            <Stack className='keyword-box' sx={{
                              justifyContent:"space-between",
                              display:"flex",
                              alignItems:"center",
                              flexDirection:"row",
                              '@media (max-width: 768px)': {
                                flexDirection:"column",
                                alignItems:"start",
                                rowGap:"0px"
                              },
                            }}>
                                <Stack justifyContent="center" alignItems="" sx={{
                                  margin:"1rem 2rem",width:"80%",
                                  '@media (max-width: 768px)': {
                                     width:"80%"
                                    },
                                  }}>
                                    <p className="keyword-title-text">{ele?.name.charAt(0).toUpperCase() + ele?.name.slice(1)}</p>
                                </Stack>
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1.5} sx={{width:token!==""?"30%":"10%",margin:"0rem 2rem",
                                '@media (max-width: 768px)': {
                                     justifyContent:"start",
                                     width:"80%",
                                     marginBottom:"15px"
                                    },
                                }}>
                                    <Button variant="contained" sx={{background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)",
                                    '@media (max-width: 420px)': {
                                     paddingBlock:"10px"
                                    },  
                                  }}
                                        onClick={()=>{sessionStorage.setItem("speaker",JSON.stringify(ele));navigate('/page',{state:{id:ele?._id,name:ele?.name}})}}>
                                        <Box sx={{ textTransform: "capitalize" }}>View</Box>
                                        <RemoveRedEyeIcon fontSize="small" sx={{marginLeft:"5px"}}/>  
                                    </Button>
                                    {token!=="" && token!==null  &&
                                    <Button variant="contained" sx={{background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)",
                                    '@media (max-width: 420px)': {
                                     paddingBlock:"10px"
                                    },    
                                  }}
                                        onClick={()=>{
                                          handleOpenAdd();
                                          setSelected(ele?._id);
                                          setName(ele?.name);
                                        }}>
                                        <Box sx={{ textTransform: "capitalize" }}>Edit</Box>
                                        <EditIcon fontSize="small" sx={{marginLeft:"5px"}}/>  
                                    </Button>}
                                    {token!=="" && token!==null  &&
                                    <Button variant="contained" sx={{background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)",
                                     '@media (max-width: 420px)': {
                                     paddingBlock:"10px"
                                    },    
                                  }}
                                        onClick={()=>{setSelected(ele?._id);handleOpen()}}>
                                        <Box sx={{ textTransform: "capitalize" }}>Delete</Box>
                                        <DeleteIcon fontSize="small" sx={{marginLeft:"5px"}}/>  
                                    </Button>}
                                </Stack>
                            </Stack>
                        )}
                    })}
                  </Box>
                  } 
                </Stack>
                }
              </Stack>

                {speakerList?.length!=0 &&  
                    <Box sx={{ display: "flex", justifyContent: "center",marginBlock:"20px"}}>
                    {speakerList?.length!=0 && 
                        <Button variant="contained" sx={{background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)"}}
                        onClick={preClick}
                        >
                            <ArrowBackIosIcon fontSize="small" />
                            <Box sx={{ textTransform: "capitalize" }}>Prev</Box> 
                        </Button>}

                        <Box sx={{background: "#E3E4EB",display: "flex",placeItems: "center",margin: " 0 10px",borderRadius: "5px"}}>
                          <Box sx={{background: `white`,color: "black",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{curPage}</Box>
                          <Box sx={{color: "gray",width: "30px",borderRadius: "5px",display: "grid",placeItems: "center"}}>of</Box>
                          <Box sx={{background: `#E3E4EB`,color: "gray",width: "30px",borderRadius: "5px",margin: "0 10px",display: "grid",placeItems: "center"}}>{NoPages}</Box>
                        </Box>

                    {speakerList?.length!=0 && 
                        <Button variant="contained" sx={{background:"linear-gradient(90deg, #a04e4e 0%, #a04e4e 100.33%)"}}
                        onClick={nextClick}
                        >
                            <Box sx={{ textTransform: "capitalize" }}>Next</Box>  
                            <ArrowForwardIosIcon fontSize="small" />
                        </Button>
                    }
                    </Box>
                }
            </Stack>
            <Footer/>

        {/* Delete Modal Section */}  
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Typography sx={{color:"#a04e4e",fontFamily: 'Helvetica',fontSize:"1.3rem",fontWeight:"540",letterSpacing:"0.2rem"}}>
            Delete Speaker
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2,mb:2,fontFamily:'Raleway',lineHeight:"30px",fontWeight:"500",fontSize:"1.1rem",color:"rgb(72,67,56)",letterSpacing:"2px"}}>
          Are You Really Want To Delete This Speaker?
          </Typography>

          <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
            <Button variant="contained" size="medium"  sx={{letterSpacing:"0.2rem",boxShadow:"none",borderRadius:"0px",padding:"7px 20px 7px 25px",marginTop:"10px",color:"#a04e4e",background: 'none',border:"1.5px solid green","&:hover": {backgroundColor: '#a04e4e',color:"white",border:"1.5px solid #a04e4e"}}}
            onClick={()=>{
              deleteSelectedData()
            }}
            >Yes</Button>
            <Button variant="contained" size="medium"  sx={{letterSpacing:"0.2rem",boxShadow:"none",borderRadius:"0px",padding:"7px 20px 7px 25px",marginTop:"10px",color:"#a04e4e",background: 'none',border:"1.5px solid red","&:hover": {backgroundColor: '#a04e4e',color:"white",border:"1.5px solid #a04e4e"}}}
            onClick={()=>{handleClose()}}
            >No</Button>
          </Stack>
        </Box>
      </Modal>

      {/* Add Modal Section */}  
        <Modal
            open={openAdd}
            // onClose={handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Stack direction="row" justifyContent='space-between' alignItems="center">
            <Typography sx={{color:"#a04e4e",fontFamily: 'Helvetica',fontSize:"1.3rem",fontWeight:"540",letterSpacing:"0.2rem"}}>
            {selected?"Edit Speaker":"Add Speaker"}
            </Typography>
            <CancelIcon sx={{cursor:"pointer",color:"#a04e4e","&:hover":{color:"red"}}} onClick={()=>{handleCloseAdd();clearField()}}/>
          </Stack>
          
          <form onSubmit={handleAddChange}>
          <TextField 
            variant="standard" 
            color="error" 
            required
            type="string" 
            placeholder='Please Type Speaker'
            sx={{Color:'#ff8c00',width:"100%",marginBlock:"5%"}}
            value={name}
            onChange={(e)=>{setName(e.target.value)}}
            />

          <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} sx={{marginTop:"10%"}}>
            <Button variant="contained" size="medium"  sx={{letterSpacing:"0.2rem",boxShadow:"none",borderRadius:"0px",padding:"7px 20px 7px 25px",marginTop:"10px",color:"#a04e4e",background: 'none',border:"1.5px solid green","&:hover": {backgroundColor: '#a04e4e',color:"white",border:"1.5px solid #a04e4e"}}}
            type='submit'
            >Save</Button>
            <Button variant="contained" size="medium"  sx={{letterSpacing:"0.2rem",boxShadow:"none",borderRadius:"0px",padding:"7px 20px 7px 25px",marginTop:"10px",color:"#a04e4e",background: 'none',border:"1.5px solid red","&:hover": {backgroundColor: '#a04e4e',color:"white",border:"1.5px solid #a04e4e"}}}
            onClick={()=>{handleCloseAdd();clearField()}}
            >Close</Button>
          </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  )
}

export default Speaker