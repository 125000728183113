import React, { useContext, useState } from 'react'
import { Backdrop, Box, Button, Fade, IconButton, InputAdornment, Modal, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Navbar from './Navbar';
import Footer from './Footer';
import '../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Apiaddress } from '../Utility';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

function PasswordReset(){

  const navigate= useNavigate();
  let key=useParams()?.id;
  console.log(key);

  const [show, setShow] = useState(false);
  const [showCon, setShowCon] = useState(false);
  const [loading,setLoading] = useState(false);
  const [err, setError] = useState(false);
  const [notShow,setNotShow] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },  
  } = useForm();

  const onSubmit=async(data)=>{

    if(data.password!==data.confirmedpassword){
        setError(true);
        return;
    }
    setError(false);
    setLoading(true);
    try {
      const url=Apiaddress + `/admin/resetpassword/${key}`;
      const res = await axios.patch(url, data);
      console.log(res?.data?.data);
      navigate("/admin");
    } catch (error) {
      toast.error("Error While Performing Operation !!",{
        position:"top-right"
        })
    }
    setLoading(false);
  }
    
  return (
    <>
    <Stack className="background" sx={{width:"100%",minHeight:"100vh",overflowY:"scroll",padding:"4%",justifyContent:"center",alignItems:"center"}}>

        <Box sx={{border:"2px solid #d4ad76",padding:"20px",borderRadius:"20px"}}>
          {/* Actual Content */}
          <Stack sx={{}}>
            <Box sx={{width: "85%",
             '@media (max-width: 500px)': {
                    width:"100%"
                  }
            }}>
               
              <Typography className='text-head-title' sx={{fontSize:"20px",fontWeight:"550",marginBottom:"15px"}}>Set Your Password</Typography>
              
              <form onSubmit={handleSubmit(onSubmit)}>
                <ul style={{color:"gray",marginLeft:"1rem",marginBottom:"15px"}}>
                    <li>
                        <p>
                        Password must be at least 8 characters long.
                        </p>
                    </li>
                    <li>
                        <p>
                        Password must contain at least one uppercase, one lowercase, one special character
                        and one number.
                        </p>
                    </li>
                </ul>
                <p style={{color: errors.password?.message ? "#da6061" : "#272d4e",marginBottom:"10px"}}>
                  Password
                </p>
                <TextField color="error"
                  sx={{
                    width: "100%",
                    fontFamily: "Product Sans",
                  }}
                  type={show ? "text" : "password"}
                  id="outlined-error-helper-text"
                  name="password"
                  fullWidth
                  {...register("password", {
                    required: "This field is required.",
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password must contain at least one uppercase, one lowercase,one number and one special character ",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  })}
                  // onChange={(e) => setMatch(e.target.value)}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={() => setShow(!show)}>
                          {show ? (
                            <VisibilityOff sx={{ color: "#99B2C6" }} />
                          ) : (
                            <Visibility sx={{ color: "#99B2C6" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <p className='forms-title' style={{color: errors.confirmedpassword?.message ? "#da6061" : "#272d4e",marginBottom:"10px"}}>
                  Confirm Password
                </p>
                <TextField color="error"
                  sx={{
                    width: "100%",
                    fontFamily: "Product Sans",
                    marginBottom:"10px"
                  }}
                  type={showCon ? "text" : "password"}
                  id="outlined-error-helper-text"
                  name="confirmedpassword"
                  fullWidth
                  {...register("confirmedpassword", {
                    required: "This field is required.",
                    pattern: {
                      value:  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password must contain at least one uppercase, one lowercase,one number and one special character ",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    // validate:(value) => value === match
                  })}
                  error={Boolean(errors.confirmedpassword)}
                  helperText={errors.confirmedpassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={() => setShowCon(!showCon)}>
                          {showCon ? (
                            <VisibilityOff sx={{ color: "#99B2C6" }} />
                          ) : (
                            <Visibility sx={{ color: "#99B2C6" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {err && (
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "1rem",
                      color: "#eb6270",
                      marginTop:"5px",
                      fontFamily: "Product Sans",

                    }}
                  >
                    Password and confirmed password does not match.
                  </Typography>
                )}

                <Stack direction="row" sx={{
                    marginTop: "20px",marginBottom:"5%",
                    '@media (max-width: 500px)': {
                      justifyContent:"center"
                    }
                  }}>
                  {loading?
                    <Button variant="contained" size="medium"  sx={{letterSpacing:"0.2rem",boxShadow:"none",borderRadius:"0px",marginTop:"10px",color:"#a04e4e",background: 'none',border:"1.5px solid red","&:hover": {backgroundColor: '#a04e4e',color:"white",border:"1.5px solid #a04e4e"}}}>
                      <CircularProgress color="inherit" size="large" sx={{width:"25px",height:"25px"}}/>
                    </Button>
                    :
                    <Button variant="contained" size="medium"  sx={{letterSpacing:"0.2rem",boxShadow:"none",borderRadius:"0px",marginTop:"10px",color:"#a04e4e",background: 'none',border:"1.5px solid red","&:hover": {backgroundColor: '#a04e4e',color:"white",border:"1.5px solid #a04e4e"}}}
                    type="submit"
                    >Save
                    </Button>
                  }
                </Stack>
              </form>
            </Box>
        </Stack>
        </Box>

    </Stack>
    </>
  )
}

export default PasswordReset;