import React, { useContext, useState } from 'react'
import { Box, Button, IconButton, InputAdornment, TextField, Typography,Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';

function TitleBar({
    title,
    speaker,
    category,
    onPushBack
}){
    const navigate=useNavigate();
    let newArray;
    if(category){
       newArray= new Array(category).fill(0);
    } 
    return (
        <Stack direction="row" sx={{margin:"15px 0px",display:'flex',flexWrap:"wrap"}}>
            <p className='navigation-name link-underline' style={{color:"#e76c05",cursor:"pointer"}}
                onClick={()=>{navigate("/")}}>
                {title}
            </p>
            {speaker && 
            <>
            <ChevronRightIcon sx={{color:"#e76c05",marginLeft:"5px"}}/>
                <p className='navigation-name link-underline' style={{color:"#e76c05",cursor:"pointer"}}
                   onClick={()=>{
                   navigate("/speaker") 
                }}
                >
                {speaker}
                </p>
            </>
            }
            <Stack direction="row" sx={{display:"flex",flexWrap:"wrap"}}>
            {
            newArray && newArray?.map((ele,index)=>{
            return(
            <Stack direction="row" sx={{width:"70px"}}>
            <ChevronRightIcon sx={{color:"#e76c05",marginLeft:"5px"}}/>
                <p className='navigation-name link-underline' style={{color:"#e76c05",cursor:"pointer"}}
                   onClick={()=>{
                   onPushBack(index) 
                }}
                >
                {`cat ${index+1}`}
                </p>
            </Stack>
            )
            })
            }
            </Stack>
        </Stack>
    );
}

export default TitleBar;